<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <profile-banner :banner="user.banner" />
    <div class="pt-4 mb-5 mb-lg-3 pb-lg-4">
      <profile-banner-inner :user="user">
        <div class="col-12 col-lg-auto order-last order-lg-0 d-flex align-items-center">
          <div class="flex-shrink-0">
            <button v-if="!user.email_verified_at"
                    :disabled="loading.verify || mailSend"
                    @click="verify"
                    class="btn btn-danger me-2 mt-1"
            >
              <span v-if="loading.verify" class="spinner-border spinner-border-sm me-2" role="status"></span>
              Подтвердить почту
            </button>
            <router-link
                to="/home/settings" class="btn btn-success">
              <i class="ri-edit-box-line align-bottom"></i> Редактировать
            </router-link>
          </div>
        </div>
        <!--end col-->
      </profile-banner-inner>
      <!--end row-->
      <div class="row">
        <div class="col-xxl-3">
          <profile-user-info :user="user" />
          <profile-social-view />
          <profile2fa />
        </div>

        <div class="col-xxl-9" data-aos="fade-left" data-aos-delay="100">
          <profile-about :user="user" />

          <div class="card" data-aos="fade-left" data-aos-delay="150">
            <div v-if="loading.loginLogs && loading.loginSessions"
                 class="card-preloader d-flex flex-column align-items-center justify-content-center">
              <div class="spinner-border text-primary" role="status">
                <span class="sr-only"></span>
              </div>
              <p class="mt-2">Загрузка...</p>
            </div>

            <div class="card-header">
              <ul class="nav nav-tabs-custom rounded card-header-tabs border-bottom-0" role="tablist">
                <li class="nav-item">
                  <a
                      class="nav-link active"
                      data-bs-toggle="tab"
                      href="#loginHistory"
                      role="tab"
                  >
                    <i class="ri ri-login-box-line"></i>
                    История авторизаций
                  </a>
                </li>
                <li class="nav-item">
                  <a
                      class="nav-link"
                      data-bs-toggle="tab"
                      href="#loginSessions"
                      role="tab"
                  >
                    <i class="ri ri-key-2-line"></i>
                    Сессии авторизация
                  </a>
                </li>
              </ul>
            </div>

            <div class="card-body">
              <div class="tab-content">
                <div class="tab-pane active" id="loginHistory" role="tabpanel">
                  <profile-logs-login
                    :logs="loginLogs"
                    v-on:reload="reloadLoginLogs"
                  />
                </div>
                <div class="tab-pane" id="loginSessions" role="tabpanel">
                  <div class="row">
                    <profile-sessions-login
                        :sessions="loginSessions"
                        v-on:delete="deleteLoginSession"
                        v-on:reload="reloadLoginSessions"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
  import Layout from "../../layouts/main.vue";
  import PageHeader from "@/components/page-header";
  import ProfileSocialView from "@/components/profile/profile-social-view";
  import { formatDate } from "@/helpers/timedate";
  import {getLoginLogs, getLoginSessions, deleteLoginSession} from "@/helpers/home/logs";
  import { verify } from "@/helpers/home/settings";
  import ProfileBanner from "@/components/profile/profile-banner";
  import ProfileBannerInner from "@/components/profile/profile-banner-inner";
  import ProfileUserInfo from "@/components/profile/profile-user-info";
  import Profile2fa from "@/components/profile/profile-2fa";
  import ProfileAbout from "@/components/profile/profile-about";
  import ProfileLogsLogin from "@/components/profile/profile-logs-login";
  import ProfileSessionsLogin from "@/components/profile/profile-sessions-login.vue";

  export default {
    components: {
      ProfileLogsLogin,
      ProfileAbout,
      Profile2fa,
      ProfileUserInfo,
      ProfileBannerInner,
      ProfileBanner,
      ProfileSocialView,
      Layout,
      PageHeader,
      ProfileSessionsLogin
    },
    data() {
      return {
        title: "Мой профиль",
        items: [
          {
            text: "Аккаунт",
            href: "/",
          },
          {
            text: "Мой профиль",
            active: true,
          },
        ],
        loading: {},
        loginLogs: false,
        loginSessions: false,
        mailSend: false,
      };
    },
    computed: {
      user: function () {
        return this.$store.getters['auth/user'];
      },
    },
    methods: {
      deleteLoginSession,
      formatDate,
      verify() {
        this.loading.verify = true;
        verify(this.user.email, {
          end: () => {
            this.loading.verify = false;
          },
          success: () => {
            this.mailSend = true;
          }
        });
      },
      async reloadLoginLogs(page = 1) {
        this.loading.loginLogs = true;
        this.loginLogs = await getLoginLogs(page);
        this.loading.loginLogs = false;
      },
      async reloadLoginSessions(page = 1) {
        this.loading.loginSessions = true;
        this.loginSessions = await getLoginSessions(page);
        this.loading.loginSessions = false;
      }
    },
    async mounted() {
      await this.reloadLoginLogs();
      await this.reloadLoginSessions();

      this.$forceUpdate();
    }
  };
</script>
